import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap, catchError } from 'rxjs/operators';

import { Quote } from '../models';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  constructor(
    private http: HttpClient,
  ) { }

  fetchQuotes(params: any): Observable<Quote> {
    const url = environment.quote_api_endpoint;
    debugger;
    const acceptedCountries = [{ "Code": "AZ" }, { "Code": "IS" }, { "Code": "SV" }, { "Code": "AL" }, { "Code": "CL" }, { "Code": "TC" }, { "Code": "UY" }, { "Code": "SI" }, { "Code": "PR" }, { "Code": "BZ" }, { "Code": "LI" }, { "Code": "LC" }, { "Code": "DE" }, { "Code": "EE" }, { "Code": "MX" }, { "Code": "BE" }, { "Code": "LU" }, { "Code": "CW" }, { "Code": "CA" }, { "Code": "IE" }, { "Code": "ZA" }, { "Code": "DK" }, { "Code": "UA" }, { "Code": "AW" }, { "Code": "AT" }, { "Code": "IT" }, { "Code": "MC" }, { "Code": "AG" }, { "Code": "GB" }, { "Code": "ME" }, { "Code": "FI" }, { "Code": "PA" }, { "Code": "NL" }, { "Code": "NI" }, { "Code": "VA" }, { "Code": "LT" }, { "Code": "HN" }, { "Code": "BR" }, { "Code": "CZ" }, { "Code": "IL" }, { "Code": "HR" }, { "Code": "PT" }, { "Code": "PY" }, { "Code": "US" }, { "Code": "HU" }, { "Code": "CR" }, { "Code": "DO" }, { "Code": "BY" }, { "Code": "ES" }, { "Code": "JM" }, { "Code": "SE" }, { "Code": "CH" }, { "Code": "EC" }, { "Code": "AR" }, { "Code": "GT" }, { "Code": "AM" }, { "Code": "NO" }, { "Code": "RS" }, { "Code": "TR" }, { "Code": "RU" }, { "Code": "PE" }, { "Code": "SK" }, { "Code": "SX" }, { "Code": "FR" }, { "Code": "BQ" }, { "Code": "LV" }, { "Code": "GR" }, { "Code": "AD" }, { "Code": "AU" }];
    if (acceptedCountries.filter(c => c.Code == params.PickupLocation.IsoCountry).length == 0 || params.PickupLocation.IsoCountry === "BR") {
      alert('Prezado cliente, não há tarifas de pré-pagamento para esta localidade. Favor entrar em contato com nossa Central de Reservas pelos telefones: (11) 3524 7525 / 0800 602 7525.\nSe preferir, envie um e-mail para specialdesk@sgrentals.com.br.');
      return of(null);
    }

    return this.http.post(url, params)
      .pipe(
        switchMap((response: any) => {
          if (response.data.items.length === 0) {
            return of(null);
          }

          const quote: Quote = {
            ...response,
            timestamp: +new Date()
          };

          return of(quote);
        }),
        catchError(
          errors => {
            throw errors;
          })
      );
  }
}
