import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromLocation from './locations.reducer';

export default fromLocation;

export * from './locations.actions';
export * from './locations.effects';

export const getLocationsFeatureState = createFeatureSelector<fromLocation.LocationsState>('locations');

export const getLocations = createSelector(
  getLocationsFeatureState,
  state => state
);
