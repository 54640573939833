import { Store } from '@ngrx/store';
import { of, Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { switchMap, take, tap, filter, catchError, retry, throwIfEmpty } from 'rxjs/operators';
import {
  CanActivate,
  Router,
} from '@angular/router';

import fromQuote, { FetchQuoteResponse, getQuote } from '../states/quote';

@Injectable({
  providedIn: 'root'
})
export class QuoteGuard implements CanActivate {
  constructor(
    private storage: Storage,
    private quoteStore: Store<fromQuote.QuoteState>,
  ) {
    this.storage.get('quote').then(quote => this.quoteStore.dispatch(new FetchQuoteResponse({ data: quote })));
  }

  canActivate(): Observable<boolean> {
    debugger;
    return this.quoteStore.select(getQuote).pipe(
      take(1),
      retry(1),
      throwIfEmpty(),
      switchMap(quote => {
        return of(!!quote.data);
      }),
    );
  }
}
