import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  templateUrl: './cultures.component.html',
  styleUrls: ['./cultures.component.scss'],
})
export class CulturesComponent implements OnInit {
  @Input() cultures: any = [];

  @Input() culture;

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  cultureChange(culture) {
    this.modalCtrl.dismiss(culture);
  }
}
