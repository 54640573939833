import { AppActions, AppActionTypes } from './app.actions';

import english from '../../../../cultures/culture.en-US.json';
import spanish from '../../../../cultures/culture.es-ES.json';
import portuguese from '../../../../cultures/culture.pt-BR.json';

import currencies from '../../../../assets/json/currencies.json';

export interface AppState {
  culture: any;
  currency: any;
  cultures: Array<any>;
  currencies: Array<any>;
}

export const initialState = {
  culture: portuguese,
  currency: {
    code: 'BRL',
    description: 'Real',
    symbol: 'R$',
    thousands: '.',
    decimal: ',',
  },
  cultures: [portuguese, spanish, english],
  currencies,
};

export function appReducer(state = initialState, action: AppActions): any {
  switch (action.type) {
    case AppActionTypes.SetCulture:
      return {
        ...state,
        culture: action.culture,
      };
    case AppActionTypes.SetCurrency:
      return {
        ...state,
        currency: action.currency,
      };
    case AppActionTypes.SetCultures:
      return {
        ...state,
        cultures: action.cultures,
      };
    case AppActionTypes.SetCurrencies:
      return {
        ...state,
        currencies: action.currencies,
      };
    default:
      return state;
  }
}
