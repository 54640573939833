import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromApp from './app.reducer';

export default fromApp;

export * from './app.actions';
export * from './app.effects';

export const state = createFeatureSelector<fromApp.AppState>('app');

export const getCulture = createSelector(
  state,
  state => state.culture
);

export const getCurrency = createSelector(
  state,
  state => state.currency
);

export const getCultures = createSelector(
  state,
  state => state.cultures
);

export const getCurrencies = createSelector(
  state,
  state => state.currencies
);
