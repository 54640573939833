import { Store } from '@ngrx/store';
import { Storage } from '@ionic/storage';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

import { CultureService } from './core/services/culture.service';
import fromAuth, { Login, Logout, getAuth } from './core/states/auth';
import { SettingsComponent } from './shared/components/modal/settings/settings.componet';

import fromApp, { getCultures, getCulture } from './core/states/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  auth: any;

  authenticate = false;

  constructor(
    private storage: Storage,
    private platform: Platform,
    private statusBar: StatusBar,
    private modalCtrl: ModalController,
    private splashScreen: SplashScreen,
    private cultureService: CultureService,
    private appStore: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private authStore: Store<fromAuth.AuthState>,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleLightContent();

      this.statusBar.backgroundColorByHexString('#002a54');

      this.statusBar.overlaysWebView(false);

      this.cultureService.setInitialCulture();

      this.authStore.select(getAuth).subscribe(auth => {
        this.authenticate = !!auth.token;
      });

      this.storage.get('auth').then(auth => {
        if (auth) {
          this.auth = auth;

          const { profile, token } = auth;

          if (token) {
            this.authStore.dispatch(new Login({
              token,
              profile,
            }));
          }
        }

        this.splashScreen.hide();
      });
    });
  }

  async onClick() {
    const modal = await this.modalCtrl.create({
      backdropDismiss: false,
      component: SettingsComponent,
    });

    await modal.present();
  }

  logout() {
    this.storage.remove('auth').then(() => {
      this.authenticate = false;

      this.authStore.dispatch(new Logout());
    });
  }
}
