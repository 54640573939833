import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  templateUrl: './currencies.component.html',
  styleUrls: ['./currencies.component.scss'],
})
export class CurrenciesComponent implements OnInit {
  @Input() currencies: any = [];

  @Input() currency;

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  currencyChange(currency) {
    this.modalCtrl.dismiss(currency);
  }
}
