import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { IonicStorageModule } from '@ionic/storage';
import { RouteReuseStrategy } from '@angular/router';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import fromApp from './core/states/app';
import fromBook from './core/states/book';
import fromUser from './core/states/user';
import fromAuth from './core/states/auth';
import fromQuote from './core/states/quote';
import fromLocation from './core/states/locations';
import fromDestinations from './core/states/destinations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { environment } from '../environments/environment';
import { SettingsComponent } from './shared/components/modal/settings/settings.componet';
import { CulturesComponent } from './shared/components/modal/cultures/cultures.componet';
import { CurrenciesComponent } from './shared/components/modal/currencies/currencies.componet';

@NgModule({
  declarations: [
    AppComponent,
    SettingsComponent,
    CulturesComponent,
    CurrenciesComponent,
  ],
  entryComponents: [
    SettingsComponent,
    CulturesComponent,
    CurrenciesComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    IonicModule.forRoot(),
    StoreModule.forRoot({}),
    StoreModule.forFeature('app', fromApp.appReducer),
    StoreModule.forFeature('quote', fromQuote.reducer),
    StoreModule.forFeature('book', fromBook.bookReducer),
    StoreModule.forFeature('user', fromUser.userReducer),
    StoreModule.forFeature('locations', fromLocation.reducer),
    StoreModule.forFeature('destinations', fromDestinations.reducer),
    StoreModule.forFeature('auth', fromAuth.authReducer),
    EffectsModule.forRoot([]),
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    Keyboard,
    StatusBar,
    SplashScreen,
     { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
