import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromDestinations from './destinations.reducer';

export default fromDestinations;

export * from './destinations.effects';
export * from './destinations.actions';

export const getDestinationsState = createFeatureSelector<fromDestinations.DestinationState>('destinations');

export const getDestinations = createSelector(
  getDestinationsState,
  state => state.data
);
