import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import {
  SetLocations,
  LocationsActionTypes,
} from './locations.actions';
import { Storage } from '@ionic/storage';

@Injectable()
export class LocationsEffects {
  constructor(
    private actions: Actions,
    private storage: Storage,
  ) { }

  @Effect({ dispatch: false })
  SetPickupLocation = this.actions
    .pipe(
      ofType<SetLocations>(LocationsActionTypes.SetLocations),
      tap(action => this.storage.set('locations', action.payload)),
    );
}
