import { BookActions, BookActionTypes } from './book.actions';

export interface BookState {
  book: any;
  loading: boolean;
  books: Array<any>;
}

export const initialState = {
  book: null,
  loading: false,
  books: [],
};

export function bookReducer(state = initialState, action: BookActions): any {
  switch (action.type) {
    case BookActionTypes.FetchBooks:
      return {
        ...state,
        loading: true,
      };
    case BookActionTypes.FetchBooksComplete:
      return {
        ...state,
        loading: false,
        books: action.payload.books.Items,
      };
    case BookActionTypes.BookRequest:
      return {
        ...state,
        loading: true,
      };
    case BookActionTypes.BookResponse:
      const { data: { book } } = action.payload.book;

      return {
        ...state,
        loading: false,
        book,
      };
    case BookActionTypes.BookReset:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
