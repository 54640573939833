import moment from 'moment';
import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { NavController, LoadingController } from '@ionic/angular';
import { map, mergeMap, tap, take } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';

import {
  FetchBooks,
  BookRequest,
  BookResponse,
  BookActionTypes,
  FetchBooksComplete,
} from './book.actions';

import { BookService } from '../../services/book.service';

@Injectable()
export class BookEffects {
  constructor(
    private actions: Actions,
    private storage: Storage,
    private service: BookService,
    private navCtrl: NavController,
    private loadingCtrl: LoadingController,
  ) {
  }

  @Effect()
  book = this.actions
    .pipe(
      ofType<BookRequest>(BookActionTypes.BookRequest),
      mergeMap(({ payload }) => this.service.book({
          id: payload.id,
          IdTransaction: payload.idTransaction,
          IdRate: payload.idRate,
          firstName: payload.user.firstName,
          lastName: payload.user.lastName,
          EmailAddress: payload.user.email,
          Phone: payload.user.mobileNumber.replace(/(?!^-)[^0-9]/g, ''),
          PickupLocationCode: payload.location.pickupLocation.code,
          DropoffLocationCode: payload.location.dropOffLocation.code,
          PickupDateTime: moment(payload.pickupDateTime).format('YYYY-MM-DD HH:mm:ss'),
          ReturnDateTime: moment(payload.ReturnDateTime).format('YYYY-MM-DD HH:mm:ss'),
          SippCode: payload.sippCode,
          IsoCountry: payload.isoCountry,
          CardNumber: payload.payment ? payload.payment.cardNumber : null,
          CardHolder: payload.payment ? payload.payment.cardHolder : null,
          ExpirationDate: payload.payment ? payload.payment.expirationDate : null,
          Cpf: payload.payment ? payload.payment.cpf : payload.user.documentNumber,
          CVV: payload.payment ? payload.payment.cvv : null,
          Installments: payload.payment ? payload.payment.installments : null,
        })
      ),
      map(book => new BookResponse({ book })),
      tap(({ payload }) => {
        const book = payload.book.data;

        this.storage.set('book', book);

        const id = book.id;
        const rate = book.idRate;

        this.loadingCtrl.dismiss();

        this.navCtrl.navigateForward(`/reservation-confirmation/${id}/${rate}`);
      }),
      take(1),
    );

    @Effect()
    books = this.actions
    .pipe(
      ofType<FetchBooks>(BookActionTypes.FetchBooks),
      mergeMap(({ payload }) => this.service.books({ userId: payload.userId })),
      map(books => new FetchBooksComplete({ books })),
    );
}
