import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import english from '../../../assets/i18n/en-US.json';
import spanish from '../../../assets/i18n/es-ES.json';
import portuguese from '../../../assets/i18n/pt-BR.json';

@Injectable({
  providedIn: 'root'
})
export class CultureService {
  constructor(
    private storage: Storage,
    private translateService: TranslateService,
  ) {
    this.translateService.setTranslation('en-US', english, true);

    this.translateService.setTranslation('es-ES', spanish, true);

    this.translateService.setTranslation('pt-BR', portuguese, true);
  }

  async setInitialCulture() {
    this.translateService.setDefaultLang('pt-BR');

    const culture = await this.storage.get('culture');

    if (culture) {
      this.setCulture(culture);
    }
  }

  getCultures() {
    return [portuguese, english, spanish];
  }

  async setCulture(culture: any) {
    this.translateService.use(culture.name);

    await this.storage.set('culture', culture);
  }
}
