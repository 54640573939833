import { DestinationsActions, DestinationsActionTypes } from './destinations.actions';

import { Destination } from '../../models';

export interface DestinationState {
  loading: boolean;
  data: Array<Destination>;
}

export const initialState: DestinationState = {
  loading: false,
  data: [],
};

export function reducer(state = initialState, action: DestinationsActions): DestinationState {
  switch (action.type) {
    case DestinationsActionTypes.FetchDestinationsRequest:
      return {
        ...state,
        loading: true,
      };
    case DestinationsActionTypes.FetchDestinationsResponse:
      return {
        ...state,
        loading: false,
        data: action.payload.destinations,
      };
    default:
      return state;
  }
}


