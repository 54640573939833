export const environment = {
  production: false,
  destinations_api_endpoint: 'https://sgtech.blob.core.windows.net/marketing-sg/destinations_b2c.json',
  location_api_endpoint: 'https://locations.sgrentals.com.br/autocomplete',
  quote_api_endpoint: 'https://sgrentals-api-testes.azurewebsites.net/QuoteB2C',
  book_api_endpoint: 'https://sgrentals-core-win.azurewebsites.net/book',
  login_api_endpoint: 'https://sgrentals-core-win.azurewebsites.net/conta/login',
  accounts_api_endpoint: 'https://sgrentals-core-win.azurewebsites.net/conta/registrar',
  forgot_password_api_endpoint: 'https://sgrentals-identity-api.azurewebsites.net/accounts/forgotpassword',
  image_path: 'https://images.hertz.com/vehicles/220x128/',
  expires: new Date().getTime() + 900000,
  auth: {
    clientID: 'ld2n3EeiulLl6wlM5Rxc4S5QhtOx3Xnr',
    domain: 'sgrentals.auth0.com',
    responseType: 'token id_token',
    audience: 'https://sgrentals.auth0.com/userinfo',
    scope: 'openid profile email',
    returnTo: 'http://sgrentals-web.azurewebsites.net'
  },
  logos: {
    'ZE': 'https://sgtech.blob.core.windows.net/sgrentals/resources/images/hertz-logo.png',
    'ZR': 'https://sgtech.blob.core.windows.net/sgrentals/resources/images/dollar-logo.png',
    'ZT': 'https://sgtech.blob.core.windows.net/sgrentals/resources/images/thrifty-logo.png',
  }
};
