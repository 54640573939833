import { Action } from '@ngrx/store';

export enum LocationsActionTypes {
  SetLocations = '[Home Page] Set Locations',
  LocationsReset = '[Locations] Locations Reset'
}

export class SetLocations implements Action {
  readonly type = LocationsActionTypes.SetLocations;

  constructor(public payload: any) { }
}

export class LocationsReset implements Action {
  readonly type = LocationsActionTypes.LocationsReset;
}

export type LocationsActions = SetLocations | LocationsReset;
