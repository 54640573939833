import { Action } from '@ngrx/store';

export enum AppActionTypes {
  AppReset = '[App] Reset',
  SetCulture = '[App] Set Culture',
  SetCurrency = '[App] Set Currency',
  SetCultures = '[App] Set Cultures',
  SetCurrencies = '[App] Set Currencies',
}

export class SetCulture implements Action {
  readonly type = AppActionTypes.SetCulture;

  constructor(public culture) { }
}

export class SetCurrency implements Action {
  readonly type = AppActionTypes.SetCurrency;

  constructor(public currency) { }
}

export class SetCultures implements Action {
  readonly type = AppActionTypes.SetCultures;

  constructor(public cultures) { }
}

export class SetCurrencies implements Action {
  readonly type = AppActionTypes.SetCurrencies;

  constructor(public currencies) { }
}

export class AppReset implements Action {
  readonly type = AppActionTypes.AppReset;
}

export type AppActions = SetCulture | SetCurrency | SetCultures | SetCurrencies | AppReset;
