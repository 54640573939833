import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromUser from './user.reducer';

export default fromUser;

export * from './user.effects';
export * from './user.actions';

export const getUserState = createFeatureSelector<fromUser.UserState>('user');

export const getUser = createSelector(
  getUserState,
  state => ({
    user: state.data,
  })
);
