import { LocationsActions, LocationsActionTypes } from './locations.actions';

import { Location } from '../../models';

export interface LocationsState {
  pickupDateTime: String;
  ReturnDateTime: String;
  pickupLocation: Location;
  dropOffLocation: Location;
}

export const initialState: LocationsState = {
  pickupDateTime: null,
  ReturnDateTime: null,
  pickupLocation: null,
  dropOffLocation: null,
};

export function reducer(state = initialState, action: LocationsActions): LocationsState {
  switch (action.type) {
    case LocationsActionTypes.SetLocations:
      return {
        ...state,
        ...action.payload
      };
    case LocationsActionTypes.LocationsReset:
      return {
        ...initialState
      };
    default:
      return state;
  }
}


