import { of } from 'rxjs';
import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { map, mergeMap, tap, catchError, switchMap } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';

import {
  SetUser,
  GetUser,
  GetUserResponse,
  UserActionTypes,
  UpdateUserFailure,
} from './user.actions';

import { UserService } from '../../services/user.service';
import { LoadingController, AlertController } from '@ionic/angular';

@Injectable()
export class UserEffects {

  constructor(
    private actions: Actions,
    private storage: Storage,
    private userService: UserService,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
  ) { }

  @Effect()
  getUser = this.actions
    .pipe(
      ofType<GetUser>(UserActionTypes.GetUser),
      mergeMap(({ payload }) => this.userService.getUser(payload)),
      map(data => new GetUserResponse(data)),
      tap(action => this.storage.set('user', action.payload)),
    );

  @Effect({ dispatch: false })
  setUser = this.actions
    .pipe(
      ofType<SetUser>(UserActionTypes.SetUser),
      tap(action => this.storage.set('user', action.payload)),
    );

    @Effect()
    updateUser = this.actions
      .pipe(
        ofType<GetUser>(UserActionTypes.UpdateUser),
        // mergeMap(({ payload }) => this.userService.updateUser(payload)),
        mergeMap(({ payload }) => of(payload.data)),
        map(data => new SetUser(data)),
        tap(async ({ payload }) => {
          await this.storage.set('user', payload.data);

          await this.loadingCtrl.dismiss();
        }),
        catchError(async errors => {
          const { message = 'Erro ao tentar atualizar dados do usuário' } = errors;

          this.loadingCtrl.dismiss();

          const alert = await this.alertCtrl.create({
            message,
            header: 'Atualizar dados do Usuário',
            buttons: ['OK']
          });

          await alert.present();

          return new UpdateUserFailure(message);
        })
      );
}
