import { Action } from '@ngrx/store';

import QuoteResponse from '../../services/responses/quote.response';

export enum QuoteActionTypes {
  FetchQuoteRequest = '[Quote Home Page] Fetch Quote Request',
  FetchQuoteResponse = '[Quote API] Fetch Quote Response',
  QuoteReset = '[Quote Home Page] Reset',
}

export class FetchQuoteRequest implements Action {
  readonly type = QuoteActionTypes.FetchQuoteRequest;

  constructor(public payload: any) { }
}

export class FetchQuoteResponse implements Action {
  readonly type = QuoteActionTypes.FetchQuoteResponse;

  constructor(public payload: QuoteResponse) { }
}

export class QuoteReset implements Action {
  readonly type = QuoteActionTypes.QuoteReset;
}

export type QuoteActions = FetchQuoteRequest | FetchQuoteResponse | QuoteReset;
