import { Action } from '@ngrx/store';

export enum BookActionTypes {
  BookReset = '[Book] Reset',
  FetchBooks = '[Book] Fetch Books',
  BookRequest = '[Book] Book Request',
  BookResponse = '[Book] Book Response',
  FetchBooksComplete = '[Book] Fetch Books Complete',
}

export class FetchBooks implements Action {
  readonly type = BookActionTypes.FetchBooks;

  constructor(public payload) { }
}

export class FetchBooksComplete implements Action {
  readonly type = BookActionTypes.FetchBooksComplete;

  constructor(public payload) { }
}

export class BookRequest implements Action {
  readonly type = BookActionTypes.BookRequest;

  constructor(public payload) { }
}

export class BookResponse implements Action {
  readonly type = BookActionTypes.BookResponse;

  constructor(public payload) { }
}

export class BookReset implements Action {
  readonly type = BookActionTypes.BookReset;
}

export type BookActions = FetchBooks | FetchBooksComplete | BookRequest | BookResponse | BookReset;
