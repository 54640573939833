import { Action } from '@ngrx/store';

export enum UserActionTypes {
  SetUser = '[User] Set User',
  UserReset = '[User] User Reset',
  UpdateUser = '[User] Update User',
  GetUser = '[User] Get User Request',
  GetUserResponse = '[User] Get User Response',
  UpdateUserFailure = '[User] Update User Failure',
}

export class SetUser implements Action {
  readonly type = UserActionTypes.SetUser;

  constructor(public payload) { }
}

export class GetUser implements Action {
  readonly type = UserActionTypes.GetUser;

  constructor(public payload) {}
}

export class GetUserResponse implements Action {
  readonly type = UserActionTypes.GetUserResponse;

  constructor(public payload) { }
}

export class UserReset implements Action {
  readonly type = UserActionTypes.UserReset;
}

export class UpdateUser implements Action {
  readonly type = UserActionTypes.UpdateUser;

  constructor(public payload) {}
}

export class UpdateUserFailure implements Action {
  readonly type = UserActionTypes.UpdateUserFailure;

  constructor(public payload) {}
}

export type UserActions = SetUser | GetUser | GetUserResponse | UserReset | UpdateUser | UpdateUserFailure;
