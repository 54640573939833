import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  Login = '[Login] Login',
  Logout = '[Login] Logout',
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;

  constructor(public payload) { }
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export type AuthActions = Login | Logout;
