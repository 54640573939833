import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, mergeMap, tap, catchError } from 'rxjs/operators';
import { NavController, ModalController, AlertController } from '@ionic/angular';

import {
  QuoteActionTypes,
  FetchQuoteRequest,
  FetchQuoteResponse,
} from './quote.actions';

import { QuoteService } from '../../services/quote.service';

@Injectable()
export class QuoteEffects {
  constructor(
    private actions: Actions,
    private storage: Storage,
    private service: QuoteService,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
  ) { }

  @Effect()
  loadQuotes = this.actions
    .pipe(
      ofType<FetchQuoteRequest>(QuoteActionTypes.FetchQuoteRequest),
      mergeMap(({ payload }) =>
        this.service.fetchQuotes(payload).
          pipe(
            map(
              response => new FetchQuoteResponse({ data: response })),
            tap(({ payload }) => {
              
              this.storage.set('quote', payload.data);
            }),
            tap(() => this.navCtrl.navigateForward('/search-results')),
            tap(() => this.modalCtrl.dismiss()),
            catchError(async () => {
              const alert = await this.alertCtrl.create({
                message: 'Erro a tentar efetuar busca',
                header: 'Busca',
                buttons: ['OK']
              });

              this.modalCtrl.dismiss();

              await alert.present();

              return new FetchQuoteResponse({ data: [] });
            }),
          )
      ));
}
