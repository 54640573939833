import { UserActions, UserActionTypes } from './user.actions';

export interface UserState {
  data: any;
  loading: boolean;
  errror: string;
}

export const initialState = {
  data: null,
  loading: false,
  error: '',
};

export function userReducer(state = initialState, action: UserActions): any {
  switch (action.type) {
    case UserActionTypes.SetUser:
      return {
        ...state,
        data: action.payload,
      };
    case UserActionTypes.GetUser:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case UserActionTypes.GetUserResponse:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case UserActionTypes.UpdateUserFailure:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UserActionTypes.UserReset:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
