import { Action } from '@ngrx/store';

import { Destination } from '../../models';

export enum DestinationsActionTypes {
  FetchDestinationsRequest = '[Destinations Home Page] Fetch Destinations Request',
  FetchDestinationsResponse = '[Destinations API] Fetch Destinations Response'
}

export class FetchDestinationsRequest implements Action {
  readonly type = DestinationsActionTypes.FetchDestinationsRequest;
}

export class FetchDestinationResponse implements Action {
  readonly type = DestinationsActionTypes.FetchDestinationsResponse;

  constructor(public payload: { destinations: Destination[] }) {}
}

export type DestinationsActions = FetchDestinationsRequest | FetchDestinationResponse;
