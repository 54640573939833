import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAuth from './auth.reducer';

export default fromAuth;

export * from './auth.effects';
export * from './auth.actions';

export const getAuthState = createFeatureSelector<fromAuth.AuthState>('auth');

export const getAuth = createSelector(
  getAuthState,
  state => ({
    token: state.token,
    profile: state.profile,
  })
);
