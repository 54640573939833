import { AuthActions, AuthActionTypes } from './auth.actions';

export interface AuthState {
  token: any;
  profile: any;
}

export const initialState = {
  token: null,
  profile: null,
};

export function authReducer(state = initialState, action: AuthActions): any {
  switch (action.type) {
    case AuthActionTypes.Login:
      const { token, profile } =  action.payload;

      return {
        ...state,
        token,
        profile,
      };
    case AuthActionTypes.Logout:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
