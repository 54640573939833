import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookService {
  constructor(
    private storage: Storage,
    private http: HttpClient) { }

  book(params): Observable<any> {
    debugger;
    const url = environment.book_api_endpoint;
    const token = this.storage.get('token');
    return this.http.post(url, params)
      .pipe(
        switchMap((resp: any) => {
          const { id } = params;

          resp.data.id = id;

          return of(resp);
        }),
      );
  }

  books(params): Observable<any> {
    const url = environment.book_api_endpoint;

    return this.http.get(url, params)
      .pipe(
        switchMap((resp) => of(resp)),
      );
  }
}
