import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { QuoteGuard } from './core/guards/quote.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    loadChildren: './pages/home/home.module#HomePageModule',
  },
  {
    path: 'search-results',
    canActivate: [ QuoteGuard ],
    loadChildren: './pages/search-results/search-results.module#SearchResultsPageModule',
  },
  {
    path: 'reservation-options/:id/:rate',
    loadChildren: './pages/reservation-options/reservation-options.module#ReservationOptionsPageModule',
  },
  {
    path: 'reservation-driver/:id/:rate',
    loadChildren: './pages/reservation-driver/reservation-driver.module#ReservationDriverPageModule',
  },
  {
    path: 'reservation-payment/:id/:rate',
    loadChildren: './pages/reservation-payment/reservation-payment.module#ReservationPaymentPageModule',
  },
  {
    path: 'reservation-confirmation/:id/:rate',
    loadChildren: './pages/reservation-confirmation/reservation-confirmation.module#ReservationConfirmationPageModule',
  },
  {
    path: 'signup',
    loadChildren: './pages/signup/signup.module#SignupPageModule',
  },
  {
    path: 'login',
    loadChildren: './pages/login/login.module#LoginPageModule',
  },
  {
    path: 'forgot',
    loadChildren: './pages/forgot/forgot.module#ForgotPageModule',
  },
  {
    path: 'user-account',
    canActivate: [ AuthGuard ],
    loadChildren: './pages/user-account/user-account.module#UserAccountPageModule',
  },
  {
    path: 'user-reservations',
    canActivate: [ AuthGuard ],
    loadChildren: './pages/user-reservations/user-reservations.module#UserReservationsPageModule',
  },
  {
    path: 'fidelity-program',
    canActivate: [ AuthGuard ],
    loadChildren: './pages/fidelity-program/fidelity-program.module#FidelityProgramPageModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
