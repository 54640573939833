import { createFeatureSelector, createSelector } from '@ngrx/store';

import Item from '../../models/item.model';

import * as fromQuote from './quote.reducer';

export default fromQuote;

export * from './quote.effects';
export * from './quote.actions';

export const quoteState = createFeatureSelector<fromQuote.QuoteState>('quote');

export const getQuote = createSelector(
  quoteState,
  state => state && state.data
);

export const getErrors = createSelector(
  quoteState,
  state => state && state.errors
);

export const isLoading = createSelector(
  quoteState,
  state => state && state.loading,
);

export const loaded = createSelector(
  quoteState,
  state => state && state.loaded,
);

export const items = createSelector(
  getQuote,
  state => {
    debugger;
    if (!state || !state.data.items) {
      return [];
    }

    return state.data.items.reduce((accum, curr) => {
      const id = `${curr.id}`;

      return [
        ...accum,
        {
          ...curr,
          id
        }];
    }, []);
  });

export const sort = dir => createSelector(
  items,
  items => {
    if (!items || items.length === 0) {
      return items;
    }

    const vehiclesByVendor = items.reduce((accum, curr) => {
      const vehicle = curr.vehicle;

      const sipp = vehicle.id;

      const vendor = curr.vendor.code;

      const pickupLocation = curr.pickupLocation.code;

      const paymentType = curr.rate.paymentType;

      accum[vendor] = accum[vendor] || [];

      accum[vendor][sipp] = accum[vendor][sipp] || [];

      accum[vendor][sipp][pickupLocation] = accum[vendor][sipp][pickupLocation] || [];

      accum[vendor][sipp][pickupLocation][paymentType] = accum[vendor][sipp][pickupLocation][paymentType] || [];

      if (accum[vendor][sipp][pickupLocation][paymentType].length === 0) {
        accum[vendor][sipp][pickupLocation][paymentType].push(curr);
      }

      return accum;
    }, []);

    const data = [];

    Object.keys(vehiclesByVendor)
      .map(vendor => Object.keys(vehiclesByVendor[vendor])
        .map(sipp => Object.keys(vehiclesByVendor[vendor][sipp])
          .map(pickupLocation => Object.keys(vehiclesByVendor[vendor][sipp][pickupLocation])
            .map(paymentType => data.push(vehiclesByVendor[vendor][sipp][pickupLocation][paymentType][0])))));

    return dir === 'desc' ? data.reverse() : data;
  }
);

export const filter = (filters: string[], dir: string) => createSelector(
  sort(dir),
  data => {
    if (!data) {
      return [];
    }

    let result = [];

    const categories = [];

    const payments = [];

    const vendors = [];

    if (filters.length > 0) {
      filters.forEach((filter) => {
        Object.keys(filter).forEach((key) => {
          if (key.indexOf('Categoria') > -1) {
            categories.push(filter[key].item);
          }

          if (key.indexOf('Pagamento') > -1) {
            payments.push(filter[key].key);
          }

          if (key.indexOf('Locadoras') > -1) {
            vendors.push(filter[key].item);
          }
        });
      });
    }

    if (categories.length > 0) {
      result = data.filter((item: { vehicle: { category: any; }; }) => (categories.indexOf(item.vehicle.category) > -1));
    }

    if (payments.length > 0) {
      result = data.filter((item: { rate: { paymentType: any; }; }) => (payments.indexOf(item.rate.paymentType) > -1));
    }

    if (vendors.length > 0) {
      result = data.filter((item: { vendor: { name: any; }; }) => (vendors.indexOf(item.vendor.name) > -1));
    }

    return [...result];
  }
);

export const item = (id, rate) => createSelector(
  items,
  items => {
    if (!items) {
      return null;
    }

    const item = items.find(item => item.id === id && item.rate.id === rate);

    return item;
  }
);

export const vendors = (filters: string[], dir: string) => createSelector(
  filter(filters, dir),
  items => items.reduce((accum, curr: Item) => {
    if (accum.indexOf(curr.vendor.code) > -1) {
      return accum;
    }

    return [
      ...accum,
      curr.vendor.code,
    ];
  }, [])
);

export const exchanges = createSelector(
  getQuote,
  state => state && state.exchanges
);

export const exchange = currency => createSelector(
  exchanges,
  exchanges => {
    if (!exchanges) {
      return null;
    }

    const exchange = exchanges.find(exchange => exchange.currency === currency.code);

    if (!exchange) {
      return {
        symbol: '$',
        thousands: ',',
        decimal: '.',
        amount: 1,
      };
    }

    return {
      symbol: currency.symbol,
      thousands: currency.thousands,
      decimal: currency.decimal,
      amount: exchange.value,
    };
  }
);

export const packages = item => createSelector(
  items,
  items => {
    if (!items) {
      return [];
    }

    const selected = curr => curr.rate.id === item.rate.id;

    const data = items.reduce((accum, curr) => {
      if (curr.vehicle.sipp !== item.vehicle.sipp ||
        curr.vendor.code !== item.vendor.code ||
        curr.pickupLocation.code !== item.pickupLocation.code) {
        return accum;
      }

      curr.selected = selected(curr);

      if (accum.find(item => item.rate.id === curr.rate.id)) {
        return accum;
      }

      const rate = {
        ...curr.rate,
        description: curr.rate.inclusions.reduce((accum, curr) => [...accum, curr.inclusion], []).join(' + ')
      };

      return [
        ...accum,
        {
          ...curr,
          rate,
        }
      ];
    }, []);

    return Object.values(data)
      .sort((a: any, b: any) => a.charges[0].amount > b.charges[0].amount ? 1 : -1);
  }
);
