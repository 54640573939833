import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Destination } from '../models';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DestinationService {
  constructor(private http: HttpClient) { }

  fetchDestinations(): Observable<Destination[]> {
    const url = environment.destinations_api_endpoint;

    return this.http.get(url).pipe(
      map((res: any) => res.data)
    );
  }
}
