import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) { }

  getUser(token): Observable<any> {
    const url = `${environment.accounts_api_endpoint}`;

    const options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${token}`,
      })
    };

    return this.http.get(url, options)
      .pipe(
        switchMap(response => of(response)),
      );
  }

  updateUser({ data, token }) {
    const url = `${environment.accounts_api_endpoint}`;

    const options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${token}`,
      })
    };

    return this.http.put(url, data, options)
      .pipe(
        switchMap(response => of(response)),
      );
  }
}
