import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Storage } from '@ionic/storage';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { take, switchMap, tap } from 'rxjs/operators';

import { CulturesComponent } from '../cultures/cultures.componet';
import { CurrenciesComponent } from '../currencies/currencies.componet';

import fromApp, {
  getCulture,
  SetCulture,
  getCultures,
  SetCurrency,
  getCurrency,
  getCurrencies,
} from '../../../../core/states/app';
import { CultureService } from '../../../../core/services/culture.service';

@Component({
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  culture: any;

  currency: any;

  cultures: any = [];

  currencies: any = [];

  constructor(
    private storage: Storage,
    private modalCtrl: ModalController,
    private cultureService: CultureService,
    private appStore: Store<fromApp.AppState>,
  ) {
    this.appStore.select(getCurrencies).pipe(
      take(1),
      switchMap((currencies: any) => of(currencies)),
    ).subscribe(async currencies => {
      this.currencies = currencies;
    });

    this.appStore.select(getCultures).pipe(
      take(1),
      switchMap((cultures: any) => of(cultures)),
    ).subscribe(async cultures => {
      this.cultures = cultures;
    });

    this.appStore.select(getCurrency).pipe(
      take(1),
      switchMap((currency: any) => {
        if (!currency) {
          return this.storage.get('currency');
        }

        return of(currency);
      }),
      tap(currency => {
        if (currency) {
          this.appStore.dispatch(new SetCurrency(currency));
        }
      })
    ).subscribe(async currency => {
      this.currency = currency;
    });

    this.appStore.select(getCulture).pipe(
      take(1),
      switchMap((culture: any) => {
        if (!culture) {
          return this.storage.get('culture');
        }

        return of(culture);
      }),
      tap(culture => {
        if (culture) {
          this.appStore.dispatch(new SetCulture(culture));
        }
      })
    ).subscribe(async culture => {
      this.culture = culture;
    });
  }

  ngOnInit() {
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async cultureChange() {
    const modal = await this.modalCtrl.create({
      component: CulturesComponent,
      backdropDismiss: false,
      componentProps: {
        culture: this.culture,
        cultures: this.cultures,
      },
    });

    await modal.present();

    const { data: culture } = await modal.onDidDismiss();

    if (culture && culture.name !== this.culture.name) {
      this.cultureService.setCulture(culture);

      this.appStore.dispatch(new SetCulture(culture));

      this.culture = culture;
    }
  }

  async currencyChange() {
    const modal = await this.modalCtrl.create({
      component: CurrenciesComponent,
      backdropDismiss: false,
      componentProps: {
        currency: this.currency,
        currencies: this.currencies,
      },
    });

    await modal.present();

    const { data: currency } = await modal.onDidDismiss();

    if (currency && currency.code !== this.currency.code) {
      this.appStore.dispatch(new SetCurrency(currency));

      this.storage.set('currency', currency);

      this.currency = currency;
    }
  }
}
