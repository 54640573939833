import { Store } from '@ngrx/store';
import { Storage } from '@ionic/storage';
import { tap, take, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { AppReset, AppActionTypes } from './app.actions';

import fromBook, { BookReset } from '../book';
import fromUser, { UserReset } from '../user';
import fromQuote, { QuoteReset } from '../quote';
import fromLocation, { LocationsReset } from '../locations';
import { of } from 'rxjs';

@Injectable()
export class AppEffects {
  constructor(
    private actions: Actions,
    private storage: Storage,
    private navCtrl: NavController,
    private userState: Store<fromUser.UserState>,
    private bookState: Store<fromBook.BookState>,
    private quoteStore: Store<fromQuote.QuoteState>,
    private locationsStore: Store<fromLocation.LocationsState>,
  ) { }

  @Effect({ dispatch: false })
  reset = this.actions
    .pipe(
      ofType<AppReset>(AppActionTypes.AppReset),
      switchMap(() => Promise.all([
          this.storage.remove('user'),
          this.storage.remove('book'),
          this.storage.remove('quote'),
          this.storage.remove('locations')
        ])
      ),
      switchMap(() => of(true)),
      tap(() => {
        this.userState.dispatch(new UserReset());
        this.bookState.dispatch(new BookReset());
        this.quoteStore.dispatch(new QuoteReset());
        this.locationsStore.dispatch(new LocationsReset());
      }),
      take(1),
    );
}
