import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromBook from './book.reducer';

export default fromBook;

export * from './book.effects';
export * from './book.actions';

export const getBookState = createFeatureSelector<fromBook.BookState>('book');

export const getBook = createSelector(
  getBookState,
  state => state.book
);

export const getBooks = createSelector(
  getBookState,
  state => state.books
);
