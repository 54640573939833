import { Injectable } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';

import {
  DestinationsActionTypes,
  FetchDestinationsRequest,
  FetchDestinationResponse,
} from './destinations.actions';

import { DestinationService } from '../../services/destination.service';

@Injectable()
export class DestinationEffects {
  @Effect()
  loadAllDestinations = this.actions
    .pipe(
      ofType<FetchDestinationsRequest>(DestinationsActionTypes.FetchDestinationsRequest),
      mergeMap(() => this.service.fetchDestinations()),
      map(destinations => new FetchDestinationResponse({ destinations }))
    );

  constructor(
    private actions: Actions,
    private service: DestinationService
  ) { }
}
