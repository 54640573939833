import { QuoteActions, QuoteActionTypes } from './quote.actions';

import { Quote } from '../../models';

export interface QuoteState {
  data: any;
  errors: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: QuoteState = {
  data: null,
  errors: null,
  loaded: false,
  loading: false,
};

export function reducer(state = initialState, action: QuoteActions): QuoteState {
  switch (action.type) {
    case QuoteActionTypes.FetchQuoteRequest:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case QuoteActionTypes.FetchQuoteResponse: {
        debugger;
        const { errors, data } = action.payload;

        return {
          ...state,
          errors,
          loaded: true,
          data: data ? data.data ? data : null : null,
        };
      }
    case QuoteActionTypes.QuoteReset: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
